import React from "react";

export const MenuIcon = ({ onClick }) => (
  <svg width={18} height={18} viewBox="0 0 18 18" onClick={onClick}>
    <path
      d="M1.8 0C0.805887 0 0 0.805887 0 1.8C0 2.79411 0.805887 3.6 1.8 3.6C2.79411 3.6 3.6 2.79411 3.6 1.8C3.6 0.805887 2.79411 0 1.8 0Z"
      fill="#6A7E94"
    />
    <path
      d="M1.8 7.20117C0.805887 7.20117 0 8.00706 0 9.00117C0 9.99528 0.805887 10.8012 1.8 10.8012C2.79411 10.8012 3.6 9.99528 3.6 9.00117C3.6 8.00706 2.79411 7.20117 1.8 7.20117Z"
      fill="#6A7E94"
    />
    <path
      d="M1.8 14.4004C0.805887 14.4004 0 15.2063 0 16.2004C0 17.1945 0.805887 18.0004 1.8 18.0004C2.79411 18.0004 3.6 17.1945 3.6 16.2004C3.6 15.2063 2.79411 14.4004 1.8 14.4004Z"
      fill="#6A7E94"
    />
    <path
      d="M8.99922 0C8.00511 0 7.19922 0.805887 7.19922 1.8C7.19922 2.79411 8.00511 3.6 8.99922 3.6C9.99333 3.6 10.7992 2.79411 10.7992 1.8C10.7992 0.805887 9.99333 0 8.99922 0Z"
      fill="#6A7E94"
    />
    <path
      d="M8.99922 7.20117C8.00511 7.20117 7.19922 8.00706 7.19922 9.00117C7.19922 9.99528 8.00511 10.8012 8.99922 10.8012C9.99333 10.8012 10.7992 9.99528 10.7992 9.00117C10.7992 8.00706 9.99333 7.20117 8.99922 7.20117Z"
      fill="#6A7E94"
    />
    <path
      d="M8.99922 14.4004C8.00511 14.4004 7.19922 15.2063 7.19922 16.2004C7.19922 17.1945 8.00511 18.0004 8.99922 18.0004C9.99333 18.0004 10.7992 17.1945 10.7992 16.2004C10.7992 15.2063 9.99333 14.4004 8.99922 14.4004Z"
      fill="#6A7E94"
    />
    <path
      d="M16.2023 0C15.2082 0 14.4023 0.805887 14.4023 1.8C14.4023 2.79411 15.2082 3.6 16.2023 3.6C17.1965 3.6 18.0023 2.79411 18.0023 1.8C18.0023 0.805887 17.1965 0 16.2023 0Z"
      fill="#6A7E94"
    />
    <path
      d="M16.2023 7.20117C15.2082 7.20117 14.4023 8.00706 14.4023 9.00117C14.4023 9.99528 15.2082 10.8012 16.2023 10.8012C17.1965 10.8012 18.0023 9.99528 18.0023 9.00117C18.0023 8.00706 17.1965 7.20117 16.2023 7.20117Z"
      fill="#6A7E94"
    />
    <path
      d="M16.2023 14.4004C15.2082 14.4004 14.4023 15.2063 14.4023 16.2004C14.4023 17.1945 15.2082 18.0004 16.2023 18.0004C17.1965 18.0004 18.0023 17.1945 18.0023 16.2004C18.0023 15.2063 17.1965 14.4004 16.2023 14.4004Z"
      fill="#6A7E94"
    />
  </svg>
);
