import React, { Component } from 'react';

class Spinner extends Component {
    render() {
        return (
            <div style={{textAlign: "center", padding:"10px"}}>
                <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        )
    }
}

export default Spinner;