import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { MenuItemText } from "../NavigationMenu/MenuItemText";
import { AppLogo } from "./AppLogo";
import { ServicesMenu } from "./ServicesMenu";

const serviceRouteList = [
  {
    title: "Диспетчер",
    name: "d24",
    note: "Аварийно-диспетчерская служба для ТСЖ и УК",
    href: "/",
  },
  {
    title: "CRM",
    name: "acc",
    note: "Система управления кабинетом жителя",
    isCurrent: true,
    href: `/?ref=acc`,
  },
  {
    title: "Должники",
    name: "debt",
    note: "Личный кабинет для работы с дебиторской задолженностью",
    href: `/?ref=debt`,
  },
];

export const AppHeader = () => {
	const ref = new URLSearchParams(useLocation().search).get("ref") || "d24";
	const currentServiceIdx = serviceRouteList.findIndex(serviceRoute => serviceRoute.name === ref)
  const [currentServiceId, setCurrentServiceId] = useState(currentServiceIdx);
  const currentService = serviceRouteList[currentServiceId];
  return (
    <header className="col-md-5 d-flex flex-row justify-content-between align-items-center">
      <div className="d-flex flex-row align-items-center">
        <AppLogo />
        <div className="col-md-6 m-4">
          <MenuItemText
            primaryText={currentService.title}
            secondaryText={currentService.note}
          ></MenuItemText>
        </div>
      </div>
      <ServicesMenu
        servicesOptions={serviceRouteList}
        currentIdx={currentServiceId}
				setCurrent={setCurrentServiceId}
      />
    </header>
  );
};
