import React, { useEffect, useRef } from "react";
import { DropdownMenu } from "./DropdownMenu";
import { MenuIcon } from "./MenuIcon";
import "./menu.css";

export const NavigationMenu = ({ openMenu, setOpenMenu, children }) => {
  const menuButtonRef = useRef(null);
  const menuContainerRef = useRef(null);
  const top = menuButtonRef?.current?.offsetTop + 30;
  const left = menuButtonRef?.current?.offsetLeft - 245;
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuContainerRef.current &&
        !menuContainerRef.current.contains(event.target)
      ) {
        setOpenMenu(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuContainerRef, setOpenMenu]);

  return (
    <div ref={menuContainerRef}>
      <div ref={menuButtonRef} style={{ display: "inline-flex" }}>
        <MenuIcon onClick={() => setOpenMenu(!openMenu)} />
      </div>
      {menuButtonRef?.current && <DropdownMenu {...{ openMenu, setOpenMenu, top, left }}>
        {children}
      </DropdownMenu>}
    </div>
  );
};
