import React from "react";
export const AppLogo = () => {
  return (
    <svg
      width="115"
      height="77"
      viewBox="0 0 115 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M111.455 13.9194L111.47 13.9262L111.485 13.9326C111.814 14.0739 112.309 14.3583 112.719 14.8864C113.112 15.3932 113.5 16.2161 113.5 17.5761V57.75C113.5 58.0686 113.392 58.5793 113.114 58.9671C112.89 59.2783 112.505 59.5978 111.659 59.5978H102.472H101.872L101.437 60.0114L88.1117 72.6969C87.7934 72.9999 87.267 72.7743 87.267 72.3347V61.0978V59.5978H85.767H57.3692H57.2756L57.1827 59.6095C56.8911 59.646 56.45 59.5891 56.13 59.3694C55.9005 59.2119 55.5282 58.8384 55.5282 57.75V17.5761V17.4932L55.5191 17.4108C55.4707 16.9748 55.5007 16.2057 55.7626 15.4807C56.0151 14.782 56.4382 14.2279 57.1258 13.9326C59.1411 13.0672 72.0858 7.24533 81.0022 3.22819C83.2343 2.2226 85.7881 2.22855 88.0167 3.24363L111.455 13.9194Z"
        stroke="#5B4593"
        stroke-width="3"
      />
      <path
        d="M0.000137329 47.9997V23.0797H10.0801C13.9535 23.0797 16.9285 24.153 19.0051 26.2997C21.1051 28.4464 22.1551 31.5264 22.1551 35.5397C22.1551 39.553 21.1051 42.633 19.0051 44.7797C16.9285 46.9264 13.9535 47.9997 10.0801 47.9997H0.000137329ZM5.42514 43.4497H9.41514C14.2451 43.4497 16.6601 40.813 16.6601 35.5397C16.6601 32.9964 16.0535 31.048 14.8401 29.6947C13.6501 28.318 11.8418 27.6297 9.41514 27.6297H5.42514V43.4497Z"
        fill="#5B4593"
      />
      <path
        d="M44.3525 30.4997H39.1725C38.9625 28.0264 37.3875 26.7897 34.4475 26.7897C33.1875 26.7897 32.1959 27.0464 31.4725 27.5597C30.7725 28.0497 30.4225 28.738 30.4225 29.6247C30.4225 29.928 30.4692 30.1964 30.5625 30.4297C30.6559 30.663 30.8309 30.8847 31.0875 31.0947C31.3442 31.2814 31.6009 31.4447 31.8575 31.5847C32.1142 31.7014 32.4875 31.8414 32.9775 32.0047C33.4909 32.1447 33.9459 32.273 34.3425 32.3897C34.7625 32.5064 35.3459 32.658 36.0925 32.8447C36.8392 33.0314 37.4925 33.2064 38.0525 33.3697C40.5025 34.0697 42.3225 34.9447 43.5125 35.9947C44.7259 37.0447 45.3325 38.5497 45.3325 40.5097C45.3325 41.933 45.0409 43.1814 44.4575 44.2547C43.8742 45.3047 43.0809 46.1447 42.0775 46.7747C41.0975 47.3814 40.0125 47.8247 38.8225 48.1047C37.6559 48.408 36.4075 48.5597 35.0775 48.5597C31.6942 48.5597 29.0459 47.7897 27.1325 46.2497C25.2425 44.6864 24.2859 42.493 24.2625 39.6697H29.6875C29.7809 42.7964 31.6592 44.3597 35.3225 44.3597C36.7459 44.3597 37.8659 44.068 38.6825 43.4847C39.5225 42.878 39.9425 42.0614 39.9425 41.0347C39.9425 40.1714 39.5575 39.5414 38.7875 39.1447C38.0409 38.748 36.4542 38.1997 34.0275 37.4997C33.8875 37.4764 33.7825 37.453 33.7125 37.4297C33.6425 37.4064 33.5492 37.383 33.4325 37.3597C33.3159 37.313 33.2109 37.278 33.1175 37.2547C32.2775 37.0214 31.6942 36.858 31.3675 36.7647C31.0409 36.648 30.5159 36.4614 29.7925 36.2047C29.0925 35.948 28.5792 35.7147 28.2525 35.5047C27.9259 35.2947 27.5175 35.003 27.0275 34.6297C26.5609 34.2564 26.2225 33.8714 26.0125 33.4747C25.8025 33.0547 25.6042 32.553 25.4175 31.9697C25.2542 31.363 25.1725 30.7097 25.1725 30.0097C25.1725 27.7464 26.0475 25.938 27.7975 24.5847C29.5475 23.2314 31.8692 22.5547 34.7625 22.5547C37.5625 22.5547 39.8492 23.2197 41.6225 24.5497C43.3959 25.8564 44.3059 27.8397 44.3525 30.4997Z"
        fill="#5B4593"
      />
      <path
        d="M82.1484 46.9967H67V43.698L75.1284 35.4513C76.0521 34.535 76.791 32.8857 76.791 31.2363C76.791 30.32 76.0521 28.6707 74.3895 28.6707C73.281 28.6707 71.8031 30.32 71.0642 31.9694L67 28.6707C68.4779 25.372 70.8795 23.7227 74.2047 23.7227C78.2689 23.7227 82.3331 26.2883 82.3331 31.2363C82.3331 33.802 80.6705 36.1843 78.2689 38.75L74.9437 42.0487H82.1484V46.9967Z"
        fill="#5B4593"
      />
      <path
        d="M99.345 38.855H101.75V43.7418H99.345V46.9997H94.5344V43.7418H83.9883V38.855L91.7591 24.0137H96.9397L89.7239 38.855H94.5344V33.9683H99.345V38.855Z"
        fill="#5B4593"
      />
    </svg>
  );
};
