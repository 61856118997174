import 'promise-polyfill/src/polyfill';
import {fetch as fetch_ie} from 'whatwg-fetch';

function postSignin(params, cb) {
    const url = "/api/user/auth";
    fetch_ie(url, 
        { 
             method : 'POST', 
               body : params, 
            headers : { 'Content-Type': 'application/json' }
        } )
    .then(res => res.json())
    .then((result) => { 
            cb(null,result);
        },(error) => {
            cb(error);
        }
    )
}

function postCookieSignin(params, cb) {
    const url = "/api/user/cookieauth";
    fetch_ie(url, 
        { 
             method : 'POST', 
               body : params, 
            headers : { 'Content-Type': 'application/json' }
        } )
    .then(res => res.json())
    .then((result) => { 
            cb(null,result);
        },(error) => {
            cb(error);
        }
    )
}

export default {
    postSignin : postSignin,
    postCookieSignin : postCookieSignin
}