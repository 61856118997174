import './App.css';
import React, { Component , Fragment } from 'react';
import Cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.css';
import Spinner from './components/helpers/Spinner';
import Network from './network/index';
import { AppHeader } from './components/AppHeader';
import { BrowserRouter as Router } from "react-router-dom";
import googlePlayBadge from "./assets/googlePlayBadge.svg";
import appleBadge from "./assets/appleBadge.svg";
class App extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading : false,
            message : "",
            isStop : false
        }
        this.postSignin = this.postSignin.bind(this);
        this.postCookieSignin = this.postCookieSignin.bind(this);
        this.cancelSignin = this.cancelSignin.bind(this);
    }

    componentDidMount() {
        // const url = new URL(window.location.href);
        // console.log( url.searchParams.get('ref') );

        if (window.location.href.indexOf("logout") > 0) {
            Cookies.remove("login");
            Cookies.remove("hash");
        } else {
            if (Cookies.get("hash") && Cookies.get("login")) {
                this.postCookieSignin(Cookies.get("login"),Cookies.get("hash"));
            }
        }
    }

    postSignin(e) {
        e.preventDefault();

        this.setState({isLoading:true, isStop: false});
        this.setState({message:""});
        Network.postSignin( 
                JSON.stringify({
                    login : e.target.login.value ,
                    password : e.target.password.value
                }), (err,res) => {
                    this.setState({isLoading : false});
                    if (this.state.isStop) {
                        Cookies.remove("login");
                        Cookies.remove("hash");
                        return;
                    }
                    if (err) {
                        this.setState({message:"Произошла ошибка"});
                    } else {
                        if (!res.ok) {
                            this.setState({message:"Неправильные логин или пароль"});
                        } else {
                            Cookies.set('hash', res.hash, { expires: 365 });
                            Cookies.set('login', res.login, { expires: 365 });
                            // window.location.href = 
                            //     res.server
                            //     + `cc/f?p=104:101:123::YES:101:P101_LOGIN_KEY_LOGIN,P101_LOGIN_KEY:${res.login},${res.secret}`;
                            this.redirect(res);
                        }
                    }
                }
        );
    }

    postCookieSignin(login,hash) {
        this.setState({isLoading:true, isStop: false});
        this.setState({message:""});
        Network.postCookieSignin( 
                JSON.stringify({
                    login : login ,
                    hash : hash
                }), (err,res) => {
		    this.setState({isLoading:false});
		    if (this.state.isStop) {
                        Cookies.remove("login");
                        Cookies.remove("hash");
                        return;
                    }
                    if (err) {
                        this.setState({message:"Произошла ошибка"});
                    } else {
                        if (!res.ok) {
                            this.setState({message:"Неправильные логин или пароль"});
                            Cookies.remove("login");
                            Cookies.remove("hash");
                        } else {
                            // window.location.href = 
                            //     res.server
                            //     + `cc/f?p=104:101:123::YES:101:P101_LOGIN_KEY_LOGIN,P101_LOGIN_KEY:${res.login},${res.secret}`;
                            this.redirect(res);
                        }
                    }
                    //this.setState({isLoading : false});
                }
        );
    }

    cancelSignin(e) {
        this.setState({isStop: true,isLoading: false});
    }

    redirect({server, login, secret}) {
        const url = new URL(window.location.href);
        const ref = url.searchParams.get('ref');
        if (ref == "acc") {
            // Если референс "ЛК УК" то ридеректим на лк ук с секретами
            window.location.href = `https://oico.app/acc/login?LOGIN_KEY_LOGIN=${login}&LOGIN_KEY=${secret}`;
        } else
        if (ref == "debt") {
            // Если референс "Проект по дебиторке" то ридеректим на проект дебиторки
            window.location.href = `https://ya.ds24.ru/debt/login?LOGIN_KEY_LOGIN=${login}&LOGIN_KEY=${secret}`;
        } else {
            // Иначе это д24 по умолчанию
            window.location.href = server + `cc/f?p=104:101:123::YES:101:P101_LOGIN_KEY_LOGIN,P101_LOGIN_KEY:${login},${secret}`;
        }
    }

    render() {
        return (
        <div className="App">
            <div className="container-fluid h-100 d-flex flex-column justify-content-center" >
						<div className="" style={{marginTop: "-25vh"}}>
            <div className="container">
                <div className="row justify-content-center">
								<Router>
									<AppHeader />
								</Router>
								
										
                </div>
            </div>

                <div className="container">
                    <div className="row justify-content-center" style={{marginTop:"40px"}}>
                    <div className="col-md-5">
                    <form onSubmit={this.postSignin}>
                        <div class="form-group">
                            <div class="form-group">
                                {
                                    //<label for="login">Логин</label>
                                }
                                <small id="loginHelp" class="form-text text-muted">Укажите ваше имя пользователя</small>
                                <input type="text" class="form-control" id="login" placeholder="Имя пользователя" />
                                
                            </div>
                            <div class="form-group">
                                {
                                    //<label for="phone">Пароль</label>
                                }
                                <small id="passwordHelp" class="form-text text-muted">Укажите пароль</small>
                                <input type="password" class="form-control" id="password" placeholder="Пароль" />
                            </div>
                            {this.state.isLoading ? <Spinner /> : ""}
                            <div class="form-group" style={{marginTop:"20px"}} >
                                {this.state.message ? this.state.message : ""}
                            </div>
                            {this.state.isLoading?
                            <a 
                            id="requestCancelSubmit" 
                            class="btn btn-primary"
                            onClick={this.cancelSignin}
                            >
                            Отменить вход
                            </a>
                            :
                            <button 
                                id="requestAddSubmit" 
                                type="submit" 
                                class="btn btn-primary"
                            >
                            Войти
                            </button>
                            }
                        </div>

                    </form>
                    </div>
                    </div>
                </div>
								</div>

            </div>
						<div className="container" style={{ marginTop:"-80px"}}>
                <div className="d-flex flex-row flex-nowrap justify-content-md-center">
                         <a className='m-2' href="https://play.google.com/store/apps/details?id=ru.ccds24rupck.appforemp" >
                                <img style={{width:"150px"}} src={googlePlayBadge} />
                        </a>
                        <a className='m-2' href="https://apps.apple.com/ru/app/%D0%B4%D0%B8%D1%81%D0%BF%D0%B5%D1%82%D1%87%D0%B5%D1%8024/id1506711221" >
                                <img style={{width:"150px"}} src={appleBadge} />
                        </a>
                    
                </div>
                </div>
        </div>)
    }
}

export default App;
