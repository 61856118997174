import React from "react";

export const D24Icon = () => {
  return (
    <svg width={35} height={42} viewBox="0 0 35 42">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.555 8.19834L19.1191 2.07855C18.068 1.59979 16.8641 1.59711 15.8119 2.07116C10.7135 4.36816 3.2914 7.70628 2.12846 8.20571C2.01052 8.25636 1.90462 8.3517 1.81682 8.59472C1.71814 8.86787 1.70774 9.17341 1.72325 9.31328L1.73371 9.40763V32.5027C1.73371 32.5971 1.74111 32.6649 1.74928 32.7109L1.82174 32.7018H19.9046V39.5172L27.0639 32.7018H33.0107C33.0858 32.7018 33.1305 32.6938 33.1527 32.688C33.162 32.6721 33.1759 32.6434 33.188 32.6008C33.1952 32.5754 33.2001 32.551 33.203 32.5304C33.2051 32.5155 33.2057 32.506 33.2058 32.503C33.2058 32.5022 33.2058 32.5018 33.2058 32.5019V9.50257C33.2058 8.90371 33.0408 8.62035 32.939 8.48917C32.8184 8.33375 32.67 8.24772 32.5722 8.20571L32.555 8.19834ZM1.68161 32.7083C1.68083 32.708 1.68045 32.7079 1.68046 32.7078L1.68161 32.7083ZM18.187 34.4194V40.8527C18.187 41.8593 19.3925 42.3761 20.1216 41.682L27.7507 34.4194H33.0107C34.5408 34.4194 34.9234 33.1416 34.9234 32.5027V9.50257C34.9234 7.58589 33.8078 6.86713 33.2499 6.62755L19.831 0.515504C18.3303 -0.168037 16.6099 -0.172163 15.1064 0.505211C9.99525 2.80795 2.59531 6.13599 1.45071 6.62755C0.1118 7.20255 -0.0635334 8.78381 0.0161635 9.50257V32.5027C0.0161635 34.2277 1.29131 34.4993 1.92889 34.4194H18.187Z"
        fill="#5B4593"
      />
      <path
        d="M16.1141 26.3462H7.44141V24.4577L12.095 19.7363C12.6238 19.2117 13.0469 18.2674 13.0469 17.3232C13.0469 16.7986 12.6239 15.8543 11.672 15.8543C11.0374 15.8543 10.1913 16.7986 9.76822 17.7428L7.44141 15.8543C8.28752 13.9658 9.66245 13.0215 11.5662 13.0215C13.893 13.0215 16.2198 14.4903 16.2198 17.3232C16.2198 18.792 15.2679 20.156 13.893 21.6248L11.9893 23.5134H16.1141V26.3462Z"
        fill="#5B4593"
      />
      <path
        d="M25.956 21.6864H27.333V24.4841H25.956V26.3493H23.2019V24.4841H17.1641V21.6864L21.613 13.1895H24.5789L20.4478 21.6864H23.2019V18.8886H25.956V21.6864Z"
        fill="#5B4593"
      />
    </svg>
  );
};