import React from "react";

export const DropdownMenu = ({ openMenu, top, left, children }) => {
  const hiddenStyle = {
		opacity: 0,
    transform: `scale(0.75, 0.5625)`,
    transition:
      "opacity 301ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 100ms",
    transformOrigin: "265px 0px",
    visibility: "hidden",
  };
	
  const showStyle = {
    ...hiddenStyle,
    visibility: "visible",
    opacity: 1,
    transform: `none`,
  };
  const restStyles = openMenu ? showStyle : hiddenStyle;
  return (
    <div
      className="menu"
      style={{
        top,
        left,
        ...restStyles,
      }}
    >
      <ul>{children}</ul>
    </div>
  );
};
